
$('#add-form-record').click(function (e) {
    e.preventDefault();
    $.ajax({
        type: "GET",
        url: $(this).attr('href'),
        success: function (result) {
            $('#ajax-modal').html(result);
            $('#ajax-modal').modal();
        }
    });
});

$('#record-form').ajaxForm({
    beforeSubmit: BeforeSubmitForm,
    success: SubmitSuccesful,
    error: AjaxError
});

function BeforeSubmitForm() {
    var record_form = $('#record-form');    
    var validator = record_form.validate({
        focusInvalid: true,
        invalidHandler: function () {
            $(this).find(":input.error:first").focus();
        }
    });

    if (record_form.valid()) {
        $("#form-save-button").attr("disabled", true);
        return true;
    } else {
        validator.focusInvalid();
        return false;
    }
}

function SubmitSuccesful(result, statusText) {
    $("#form-save-button").attr("disabled", false);
    if (result.Status == "Error") {
        toastr["error"](result.Message);
    } else {
        var page_refresh = $('#page-refresh')[0];
        if (page_refresh) {
            location.reload();
        } else {
            $("[id$='record-table']").DataTable().ajax.reload();
        }
        $('#ajax-modal').modal('hide');
        toastr["success"](result.Message);
    }
}

function AjaxError(result, result2) {
    $("#form-save-button").attr("disabled", false);
    toastr["error"]('Something went wrong. Please try again.');
}
