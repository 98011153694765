var selectionType;
var selectionInput;

$.fn.datepicker.dates['tr'] = {
    days: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
    daysShort: ["Pz", "Pzt", "Sal", "Çrş", "Prş", "Cu", "Cts"],
    daysMin: ["Pz", "Pzt", "Sa", "Çr", "Pr", "Cu", "Ct"],
    months: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
    monthsShort: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
    today: "Bugün",
    clear: "Temizle"
};

$.fn.tableResp = function () {
    var i = [];
    this.find("th").each(function () {
        i.push($(this).html())
    }), this.find("tr").each(function () {
        $(this).find("td").each(function (t) {
            $(this).attr("data-title", i[t]);
        });
    });
};

function imagewindow(type, input, value) {
    var w = 900;
    var h = 600;
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);

    var win = window.open("/Media", "popupWindow", "width=" + w + ", height=" + h + ", left=" + left + ", top=" + top + ", menubar=no, scrollbars=yes, resizable=no");
    Cookies.set('selectionType', type);
    Cookies.set('targetInput', input);
    Cookies.set('targetValue', value);
}

$(document).ready(function () {

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };


    var sideMenu = $('#side-menu').metisMenu();

    $("#toggleNav").click(function () {
        $("body").toggleClass("mobileNavToggle");
        return false;
    });

    $("#example").tableResp();

});

$('#ajax-modal').on('show.bs.modal', function () {

    $('#record-form').ajaxForm({
        beforeSubmit: BeforeSubmitForm,
        success: SubmitSuccesful,
        error: AjaxError
    });

    function BeforeSubmitForm() {
        $("#form-save-button").attr("disabled", true);
        if (!$('#record-form').valid())
            return false;
    }

    function SubmitSuccesful(result, statusText) {
        $("#form-save-button").attr("disabled", false);
        if (result.Status == "Error") {
            toastr["error"](result.Message);
        } else {
            var page_refresh = $('#page-refresh')[0];
            if (page_refresh) {
                location.reload();
            } else {
                $("[id$='record-table']").DataTable().ajax.reload();                
                $("[id$='payroll-table']").DataTable().ajax.reload();
            }
            $('#ajax-modal').modal('hide');
            toastr["success"](result.Message);
        }
    }

    function AjaxError(result, result2) {
        $("#form-save-button").attr("disabled", false);
        toastr["error"]('Something went wrong. Please try again.');
    }

    var slc = function () {
        FormInputInit();
    };

    setTimeout(slc, 1000);
});

$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    $("[data-type='select2']").select2();
});

function FormInputInit() {

    $("[data-type='select2']").select2();

    $("[data-type='date']").datepicker({
        orientation: "left",
        format: 'dd/mm/yyyy',
        autoclose: true
    });

}

function sortArray(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}